<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">药前分笼</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">{{ mediaDetail.createTime | formatDay }} 药前体重、瘤径记录</h4>
        <div>
          <el-button size="small" plain class="return" @click="exportExcel">导出</el-button>
          <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,0)">返回列表页</el-button>
        </div>
      </div>
      <table class="table specTable">
        <tr>
          <th>方案编号</th>
          <td>{{ activeInfo.number }}</td>
          <th>动物品系</th>
          <td>{{ activeInfo.strain }}</td>
          <th>操作室地点</th>
          <td colspan="2">{{ mediaDetail.location }}</td>
        </tr>
        <tr>
          <th>使用仪器</th>
          <td colspan="6">
            <span v-for="item in mediaDetail.instrument">[ 型号：{{ item.instruModel }}，名称：{{
                item.instrumentName
              }} ] </span>
          </td>
        </tr>
        <tr>
          <th>笼舍号</th>
          <th>笼架号</th>
          <th>耳号</th>
          <th>性别</th>
          <th>体重 (g)</th>
          <th>瘤径a (mm)</th>
          <th>瘤径b (mm)</th>
        </tr>
        <template v-for="item in mediaDetail.cageDtoList">
          <tr v-for="(obj,index) in item.preMediDetailList">
            <td :rowspan="item.preMediDetailList.length" v-if="index==0">{{ item.cageNo }}</td>
            <td :rowspan="item.preMediDetailList.length" v-if="index==0">{{ item.frameNo }}</td>
            <td>{{ obj.overbit }}</td>
            <td>{{ obj.gender == 0 ? '♀' : '♂' }}</td>
            <td>{{ obj.weight }}</td>
            <td>{{ obj.tumorSizeA }}</td>
            <td>{{ obj.tumorSizeB }}</td>
          </tr>
        </template>
        <tr>
          <td>操作人</td>
          <td colspan="2">{{ mediaDetail.createName }}</td>
          <td colspan="2">日期</td>
          <td colspan="2">{{ mediaDetail.createTime  | formatDay }}</td>
        </tr>
        <template v-if="mediaDetail.state>1">
          <tr>
            <td>复核人</td>
            <td colspan="2">{{ mediaDetail.reviewName }}</td>
            <td colspan="2">日期</td>
            <td colspan="2">{{ mediaDetail.reviewDate | formatDay }}</td>
          </tr>
        </template>
      </table>
      <!--      复核-->
      <template v-if="mediaDetail.state==1 && checkState">
        <h4 class="title">药前体重、瘤径记录复核</h4>
        <el-form ref="medicine" size="small" :model="medicine" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="medicine.state" class="radioGroup">
              <el-radio :label="2">复核通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" placeholder="请输入复核意见" v-model="medicine.reviewRemark"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('medicine')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else-if="mediaDetail.state==1 &&!checkState"></template>
      <template v-else>
        <h4 class="title">药前体重、瘤径记录复核</h4>
        <div class="desc">
          <p>复核结果：{{ mediaDetail.state == 2 ? '复核通过' : mediaDetail.state == 3 ? '复核拒绝' : '' }}</p>
          <p>复核意见：{{ mediaDetail.reviewRemark }}</p>
          <span>复核人：{{ mediaDetail.reviewName }}</span>
          <span>复核日期：{{ mediaDetail.reviewDate | formatDay }}</span>
        </div>
      </template>

    </div>
  </div>

</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import moment from "moment";
import {mapState} from "vuex";

moment.locale("zh-cn")

export default {
  name: "medicineEnd",
  props: ['id', 'infoId'],
  data() {
    return {
      activeInfo: {},
      mediaDetail: {},
      medicine: {
        state: 2,
        reviewRemark: "",
      },
      rules: {
        reviewRemark: [{required: true, message: '请输入拒绝原因', trigger: 'blur'}]
      },
      checkState: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    validateType() {
      return this.medicine.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
      // console.log(res)
    })
    this.getData()
  },
  methods: {
    getData() {
      this.$get('/pre/medi/data/' + this.infoId).then(res => {
        if (res.status == 200) {
          if (this.user.userId == res.data.reviewer) {
            this.checkState = true
          }
          res.data.instrument = JSON.parse(res.data.instrument)
          this.mediaDetail = res.data
        }
      })
    },
    // 导出
    exportExcel() {
      let name = moment(this.mediaDetail.createTime).format('YYYY-MM-DD') + '药前体重、瘤径记录表';
      let wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.medicine.id = this.mediaDetail.id
          this.$put('/pre/medi', this.medicine).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$emit('changePage', 1, null, 0)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

</style>
